import React from 'react';
import { PathService } from '../../services';
import { OurInsightsRiskSnapshotsContext, TemplateProps } from '../../types';
import { OurInsightsRiskSnapshotsView } from '../../views';

const OurInsightsRiskSnapshots: React.FunctionComponent<
  TemplateProps<OurInsightsRiskSnapshotsContext>
> = ({ location, pageContext }) => {
  PathService.set(location.pathname);
  return <OurInsightsRiskSnapshotsView posts={JSON.parse(pageContext.posts)} />;
};

export default OurInsightsRiskSnapshots;
